<template>
  <div class="footer">
    <p class="links">
      <a> <router-link to="/">Home</router-link> </a> |
      <a> <router-link to="/about">About</router-link> </a> |
      <a> <router-link to="/shop">Products</router-link> </a>
      <router-view />
    </p>
    <p>
      Established in 2021
      <br />
      Artwork Design: Henk Boerée
      <br />
      Photo Credits:
      <a href="https://www.instagram.com/sebas.ko/" id="credlinks"
        >Sebas Kops
      </a>
      <br />
      Website design & developement:
      <a href="https://nicolettaradice.xyz/" id="credlinks">Nicoletta Radice</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.footer {
  font-family: Helvetica;
  font-size: 0.5em;
  line-height: 1.1em;
  text-align: center;
  border-top: 3px solid rgba(245, 126, 32, 0.7);
  padding: 1em;
  margin-top: 50px;
}

.links {
  font-size: 1.3em;
  margin-top: 1em;
}

.links a {
  color: #f57e20;
}

p {
  margin-top: 1vh;
  margin-bottom: 2vh;
}

#credlinks {
  color: #f57e20;
}
</style>
