<template>
  <div class="container-all">
    <div class="logo-container">
      <a class="logo" href="/"><img src="../assets/logo.png" alt="logo"/></a>
    </div>

    <div
      class="container"
      id="container"
      v-bind:style="{ backgroundImage: changeBackgroundImage() }"
    >
      <div class="links-container flex-container">
        <h1>
          <router-link to="/shop" class="links flex-item"
            ><i class="fas fa-shopping-cart"></i
          ></router-link>
        </h1>
        <h1>
          <a
            href="https://www.instagram.com/fixated.fgfs/"
            class="links flex-item"
            ><i class="fab fa-instagram-square"></i
          ></a>
        </h1>
        <h1>
          <a
            href="https://www.youtube.com/channel/UC7wtQRWnda3pRwMwBj2LGfQ"
            class="links flex-item"
            ><i class="fab fa-youtube"></i
          ></a>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage",

  data() {
    return {
      images: [
        {
          img: require("../assets/background/1.jpg")
        },
        {
          img: require("../assets/background/2.jpg")
        },
        {
          img: require("../assets/background/3.jpg")
        },
        {
          img: require("../assets/background/4.jpg")
        },
        {
          img: require("../assets/background/5.jpg")
        }
      ],
      item: ""
    };
  },
  methods: {
    changeBackgroundImage() {
      var item = this.images[Math.floor(Math.random() * this.images.length)]
        .img;
      //test 1:
      return "url(" + item + ")";

      //test 2:
      // document.body.style.backgroundImage = "url('./assets/img6.jpg')";

      //test 3:
      // document.body.style.background= 'red'
    }
  },
  created: function() {
    this.changeBackgroundImage();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  margin-top: -15vh;
  padding: 0;
  padding-top: 0vh;
  text-align: left;
  width: 100vw;
  max-width: 100vw;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 0;
  margin: 0 auto;
  margin-top: 0vh;
  list-style: none;
  text-transform: uppercase;
}

.flex-item {
  height: auto;
}

.logo-container {
  width: 100%;
  height: auto;
  z-index: 0;
  margin-top: 0vh;
  /* border-bottom: 1px solid rgba(245, 126, 32, 0.7); */
  /* background-color:#f57e20; */
  background: rgb(23, 23, 23);
  /* background: black; */
}

.logo-container img {
  display: block;
  margin: auto;
  width: 50vw;
}

/* desktop and ipad pro*/
@media (min-width: 800px) {
  .container {
    text-align: center;
    width: 95vw;
    margin: 0 auto;
    height: 100vh;
    max-width: 95vw;
    background-position: center;
    background-size: 100%;
  }
  .links {
    visibility: hidden;
  }

  .logo-container img {
    display: block;
    margin: auto;
    width: 15vw;
    /* margin-top: 2vh; */
  }
}

/* mobile */
@media (max-width: 800px) {
  .links {
    font-size: 9vw;
    visibility: hidden;
    text-shadow: 4px 4px 4px rgba(255, 255, 255, 0.7);
  }
  .container {
    /* overflow: hidden; */
    width: 92%;
    max-width: 92%;
    height: 63vh;
    background-size: cover;
    padding-left: 4vh;
    padding-right: 4vh;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 100%;
  }
}
</style>
