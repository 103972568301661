<template>
  <div class="none">
    <div id="nav">
      <nav
        class="navbar navbar-expand-lg navbar-dark"
        style="background-color: #171717;"
      >
        <div
          class="collapse navbar-collapse w-100 order-1 order-lg-0"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link"><router-link to="/">Home</router-link></a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                ><router-link to="/about">About</router-link></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link"
                ><router-link to="/shop">Products</router-link></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.instagram.com/fixated.fgfs/"
                >Instagram</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.youtube.com/channel/UC7wtQRWnda3pRwMwBj2LGfQ"
                >Youtube</a
              >
            </li>
          </ul>
        </div>
        <div class="d-flex w-100 order-0">
          <div class="w-100">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
            >
              <span class="navbar-toggler-icon icon-bar">
                <i
                  class="fas fa-bars"
                  style="color:rgb(245, 126, 32); font-size:30px;"
                ></i
              ></span>
            </button>
          </div>
          <span class="w-100"></span>
          <a class="navbar-brand" href="/">
            <img src="../src/assets/logowhite.png" height="30" alt="logo" />
          </a>
        </div>
      </nav>
    </div>
    <router-view />
  </div>
</template>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400&display=swap"); */
/* @font-face {
  font-family: "BluuNext";
  src: url("../src/webfonts/BluuNext-Titling.ttf") format("truetype");
} */

@font-face {
  font-family: "Karrik";
  src: url("../src/TFF/Karrik-Regular.ttf") format("truetype");
}

#app {
  font-family: "Karrik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: white;
  background: #171717;
  width: 100vw;
  height: auto;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  box-sizing: border-box;
}

.navbar {
  margin-left: 0px;
  z-index: 99;
  border-bottom: 3px solid rgba(245, 126, 32, 0.7);
  overflow-x: hidden;
  font-size: 0.8em;
}

#nav a {
  font-weight: bold;
  color: white;
  text-align: left;
  /* padding: 0; */
}

#nav a.nav-link {
  /* padding-left: 10px; */
  text-transform: uppercase;
  font-weight: 600;
  padding: 1vh;
  padding-right: 2vh;
}

#nav a:hover {
  color: #f57e20;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #f57e20;
}
</style>
